import { Injectable } from '@angular/core';
import { IDeposit } from '@libs/interfaces';
import { ApiService } from '@libs/app.services/api.service';
import { MapResponseData } from '@libs/rxjs/operators.rxjs';
import { WALLET_CODE } from '@libs/interfaces';
import { assign, groupBy } from 'lodash';
import { map_lodash } from '@libs/functionals/functionals';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DepositsService {
  public loading: boolean;
  constructor(public api: ApiService) {}
  userWalletPartial(user_id: string, code: WALLET_CODE, offset: number, limit: number): Observable<IDeposit[]> {
    return this.api.get(`deposits/user/partial/${user_id}/${code}/${offset}/${limit}`).pipe(MapResponseData);
  }
  depositsByDayByCurrency() {
    return this.api.get('day/deposits/currency').pipe(MapResponseData);
  }
  depositsByDayOfCurrency(code: WALLET_CODE) {
    return this.api.get(`day/deposits/currency/${code}`).pipe(
      MapResponseData,
      map(res => this.splitByMoths(res.data)),
    );
  }
  private splitByMoths(res) {
    const data = res.map(ele =>
      assign(ele, {
        timestamp: new Date(ele.day).getTime(),
        year: +ele._id.substr(0, 4),
        month: +ele._id.substr(5, 2),
      }),
    );
    return this.averageYear(data);
  }
  private averageYear(data) {
    const years = map_lodash(groupBy(data, 'year'), ele => ele).reverse();
    return years.map((year: any) => {
      const months = this.averageMonth(year);
      return {
        year: year[0].year,
        deposited: months.reduce((a, b: any) => a + b.deposited, 0),
        months: months,
        show: false,
      };
    });
  }
  private averageMonth(year) {
    const months = map_lodash(groupBy(year, 'month'), ele => ele).reverse();
    return months.map((days: any) => {
      return {
        month: +days[0].month,
        deposited: days.reduce((a, b: any) => a + b.deposited, 0),
        days: days,
        show: false,
      };
    });
  }
}
